<template>
  <v-card>
    <v-container class="pt-0 pl-0">
      <!-- <v-row>
        <v-col cols="12" :md="showHelpConfigAb ? '8' : '12'" class=""> -->
      <!-- <v-col cols="12" :md=" '12'" class=""> -->
      <v-card-title class="py-0">
        <v-row>
          <v-col cols="12" md="12" class="pt-1 pb-0">
            <span class="pl-0 primary--text">{{ configTitle }} </span>
          </v-col>
          <!-- <v-col cols="12" md="1" class="pb-0 pt-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="toggleHelpConfig()"
                >
                  <v-icon>
                    {{ helpIcon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ textoAyudaConfig }}  </span>
            </v-tooltip>
          </v-col> -->
        </v-row>
      </v-card-title>
      <!-- <v-card-text class="pt-2 pb-0 text-justify">
        <span class="red--text">{{ textoAyudaConfig }} </span>
      </v-card-text> -->
      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConfig()"
        >
          <v-row>
            <!-- Url-->
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="URL del Web service"
                v-model="url"
                :rules="
                  rules.required.concat(
                    rules.requiredTrim(url),
                    rules.validateUrl
                  )
                "
                hint="Formato de URL: http://gecros.com"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Id del usuario-->
            <v-col cols="12" md="7" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Usuario"
                persistent-hint
                hint="ID del usuario en Alfa Beta"
                v-model="idUserAb"
                :rules="rules.required"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Contraseña del usuario-->
            <v-col cols="12" md="5" class="py-0">
              <v-text-field
                :append-icon="
                  !passUserAb
                    ? ''
                    : currentPassTextType
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append="
                  () => (currentPassTextType = !currentPassTextType)
                "
                :type="currentPassTextType ? 'password' : 'text'"
                outlined
                dense
                label="Contraseña"
                v-model="passUserAb"
                autocomplete="off"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <!-- Repositorio-->
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Repositorio"
                persistent-hint
                hint="D:\Adjuntos\"
                v-model="repositorio"
                :rules="rules.required"
                autocomplete="off"
              >
                <template v-slot:append-outer>
                  <v-tooltip right max-width="20%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span
                      >Directorio para la descarga del manual farmacéutico el
                      cual tiene que tener permisos de lectura/escritura para el
                      usuario del IIS.</span
                    >
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                class="my-0 py-0"
                v-model="parametroGeneralMuestraGrabaPrecioUnitario"
                label="Mostrar opción 'Grabar precio unitario' al generar proceso de actualización"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="isLoadingBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <!-- </v-col> -->
      <!-- <v-col
          v-if="showHelpConfigAb"
          cols="12"
          :md="showHelpConfigAb ? '4' : '0'"
          class="p-0"
        >
          <v-banner class="pr-5 pl-0" outlined tag="v-card" rounded>
            <v-card-text class="text-justify error--text p-0">
              {{ textoAyudaConfig }}
            </v-card-text>
          </v-banner>
        </v-col> -->
      <!-- </v-row> -->
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfigProcAlfaBeta",
  components: {
    GoBackBtn
  },
  data: () => ({
    configTitle: "Configuración del proceso de actualización Alfa Beta",
    rules: rules,
    routeToGo: "ProcesoActAlfaBeta",
    settingsIcon: enums.icons.SETTINGS,
    helpIcon: enums.icons.HELP,
    infoIcon: enums.icons.SNB_INFO,

    isFormValid: false,
    isLoadingBtn: false,
    abConfigId: null,
    url: null,
    idUserAb: null,
    passUserAb: null,
    repositorio: null,
    currentPassTextType: String,
    parametroGeneralMuestraGrabaPrecioUnitario: false,
    showHelpConfigAb: true,
    textoAyudaConfig:
      "Para la descarga del archivo de actualización del manual, es necesario configurar un repositorio, al cual tenga permiso de escritura/lectura el usuario del IIS."
    //archivo: ""
  }),
  created() {
    this.setConfig();
  },
  methods: {
    ...mapActions({
      getConfigActualizacionAB: "prestadores/getConfigActualizacionAB",
      saveConfigActualizacionAB: "prestadores/saveConfigActualizacionAB",
      setAlert: "user/setAlert"
    }),
    toggleHelpConfig() {
      this.showHelpConfigAb = !this.showHelpConfigAb;
    },
    async setConfig() {
      const response = await this.getConfigActualizacionAB();
      if (response != null) {
        this.abConfigId = response.abConfigId;
        this.url = response.url;
        this.idUserAb = response.idUserAb;
        this.passUserAb = response.passUserAb;
        this.parametroGeneralMuestraGrabaPrecioUnitario =
          response.parametroGeneralMuestraGrabaPrecioUnitario;
        this.repositorio = response.repositorio;
      }
    },
    async saveConfig() {
      this.isLoadingBtn = true;
      const data = {
        abConfigId: this.abConfigId ?? null,
        url: this.url,
        idUserAb: this.idUserAb,
        passUserAb: this.passUserAb,
        parametroGeneralMuestraGrabaPrecioUnitario: this
          .parametroGeneralMuestraGrabaPrecioUnitario,
        repositorio: this.repositorio
      };
      try {
        const res = await this.saveConfigActualizacionAB(data);
        if (res === true) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.isLoadingBtn = false;
          this.closeModal();
        }
      } catch {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadConfig");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
